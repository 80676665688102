@import "configs.scss";

.master-header {
  padding: 10px 0;
  .wrapper {
    @extend .container;
    @extend .flex-rsb;

    .logo-c {
      img {
        height: 65px;
      }
    }
    .menus-c {
      @extend .flex-row;
      a {
        text-transform: uppercase;
        display: block;
        color: #555;
        font-weight: 700;
        font-size: 1rem;
        padding: 0 10px;
        text-decoration: none;
        transition: ease-in-out all 0.3s;
        &:hover {
          color: $secondary;
        }
        &.give {
          color: #fff;
          background-color: $secondary;
          border-radius: 50px;
          @extend .btn;
          padding-left: 20px;
          padding-right: 20px;
          font-weight: bold;
          text-transform: unset;
        }
      }
    }
    .sm-menus--toggler {
      font-size: 1.3rem;
    }
    @include media-breakpoint-down(md) {
      .menus-c {
        display: none !important;
      }
      .logo-c {
        img {
          height: 40px !important;
        }
      }
    }
  }
}
.mb-menus-c {
  a {
    display: block;
    padding: 10px;
    color: #555;
    text-decoration: none;
    font-size: 1.2rem;
    &:hover {
      background-color: darken($color: #fff, $amount: 5%);
      color: $primary;
    }
    &.give {
      width: 100%;
      margin-top: 1rem;
      color: #fff;
      background-color: $secondary;
      border-radius: 50px;
      @extend .btn;
      padding-left: 20px;
      padding-right: 20px;
      font-weight: bold;
      text-transform: unset;
    }
  }
}

.m-footer {
  padding: 100px 0;
  background-color: $dark;

  .wrapper {
    @extend .container;
    .s--title {
      color: white;
      font-size: 2rem;
      margin-bottom: 2rem;
      font-weight: 900;
    }
    .subtitle {
      font-size: 1.2rem;
      color: $secondary;
      font-weight: 700;
    }
    ul {
      @extend .list-unstyled;
      a {
        display: inline-block;
        color: white;
        text-decoration: none;
        font-size: 1.1rem;
        margin-bottom: 1rem;
        transition: all ease-in-out 0.3s;
        &:hover {
          color: $secondary;
        }
      }
      p {
        font-size: 1.1rem;
        color: white;
      }
    }
  }
}

.contact-page {
  .m--intro {
    padding: 5vh 0;
    background-color: $primary;
    min-height: 50vh;
    color: white;
    @extend .flex-middle;
    text-align: center;
    h1 {
      @extend .h1;
      margin-bottom: 1rem;
      font-weight: 900;
    }
    h4 {
      @extend .h5;
    }
  }
  .c-wrapper {
    @extend .container;
    padding: 3rem 10px;
    .infos-c {
      .subtitle {
        font-size: 1.4rem;
        color: black;
        font-weight: 700;
        margin-bottom: 1rem;
      }
      ul {
        @extend .list-unstyled;
        margin-bottom: 2rem;
        a {
          display: inline-block;
          color: #222;
          text-decoration: none;
          font-size: 1.4rem;
          margin-bottom: 1rem;
          transition: all ease-in-out 0.3s;
          &:hover {
            color: $secondary;
          }
        }
      }
      p {
        font-size: 1.4rem;
        color: #222;
      }
    }
  }
}
.error-404-p {
  @extend .flex-middle;
  padding: 5vh 10px;
  margin-bottom: 3rem;
  .p--wrapper {
    width: 100%;
    max-width: 570px;
    text-align: center;
    img {
      width: 80%;
      max-width: 300px;
      margin-bottom: 2rem;
    }
    h1 {
      @extend .h2;
      color: #555;
      margin-bottom: 1rem;
    }
    h4 {
      @extend .h6;
      @extend .text-muted;
    }
  }
}

.h--hiro {
  min-height: 70vh;
  background-image: url("../../public/hiro.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center right;
  @extend .flex-middle;
  .hi--wrapper {
    @extend .container;
    color: white;
    h1 {
      max-width: 50%;
      text-transform: uppercase;
      font-weight: 900;
      margin-bottom: 2rem;
      @include media-breakpoint-down(md) {
        max-width: unset;
      }
    }
    h3 {
      @extend .h5;
      color: #aaa;
      text-transform: capitalize;
    }
    a {
      @extend .btn;
      @extend .btn-primary;
      color: #fff;
    }
  }
}
.h--top {
  .themes {
    @extend .flex-row;
    flex-wrap: wrap;
    > div {
      @include media-breakpoint-down(md) {
        width: 100%;
      }
      width: 50%;
      height: 150px;
      @extend .flex-middle;
      padding: 10px 20px;
      h2 {
        @extend .h3;
        @extend .titled-font;
        font-weight: 700;
      }
      h4 {
        font-size: 1rem;
        &::before {
          content: "« ";
        }
        &::after {
          content: " »";
        }
      }
      &.f-side {
        background-color: $secondary;
        color: white;
        justify-content: flex-end;
        text-align: right;
      }
      &.s-side {
        background-color: $primary;
        color: white;
        justify-content: flex-start;
        text-align: left;
      }
    }
  }
}
.h--vision {
  min-height: 50vh;
  padding: 10vh 10px;
  .v--wrapper {
    @extend .container;
    @extend .row-section-content;
    img {
      border-radius: 5px;
    }
    .caption-container {
      max-width: 470px;

      h1 {
        color: $primary;
        margin-bottom: 1rem;
        @extend .titled-font;
      }
      p {
        text-align: justify;
        font-size: 1.4rem;
        color: #555;
        margin-bottom: 1.5rem;
      }
    }
  }
}
.h--c--section {
  padding: 10vh 10px;
  @extend .flex-middle;
  .h--wrapper {
    @extend .flex-row;
    width: 100%;
    max-width: 1024px;
    .img-container {
      width: 50%;
      min-width: 50%;
      img {
        width: 100%;
        border-radius: 5px;
      }
    }
    .caption-container {
      padding-right: 5%;
      h1 {
        color: $primary;
        margin-bottom: 1.3rem;
        font-weight: bold;
        @extend .h3;
        @extend .titled-font;
      }
      p {
        font-size: 1.1rem;
        color: #555;
        margin-bottom: 1.5rem;
        text-align: justify;
      }
    }
    @include media-breakpoint-down(md) {
      flex-wrap: wrap;
      .img-container,
      .caption-container {
        width: 100% !important;
        margin-bottom: 1rem;
      }
    }
  }
}
.row-section-content {
  @extend .flex-row;
  align-items: flex-start;
  flex-wrap: nowrap;

  .img-container {
    width: 50%;
    min-width: 50%;
    text-align: right;
    padding-right: 2%;
    img {
      width: 100%;
      max-width: 380px;
    }
  }
  .caption-container {
    width: 50%;
    padding-left: 2%;
  }
  @include media-breakpoint-down(md) {
    flex-wrap: wrap;
    .img-container,
    .caption-container {
      width: 100% !important;
      margin-bottom: 1rem;
    }
  }
}

.about-p {
  margin-bottom: 10vh;
  .s--title {
    position: relative;
    min-height: 50vh;
    @extend .flex-middle;
    text-align: center;
    background-color: $primary;
    margin-bottom: 3rem;
    background-image: url("../../public/ministry-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    .overlay {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: #0089cd41;
      z-index: 0;
    }

    h1 {
      font-size: 4rem;
      font-weight: 900;
      color: #fff;
      z-index: 1;
      text-transform: uppercase;
    }
  }
  .wrapper {
    @extend .container;
    p {
      font-size: 1.4rem;
      margin-bottom: 2rem;
      color: #555;
      text-align: justify;
      .floatter {
        width: 30%;
        margin-right: 20px;
        float: left;
        border-radius: 5px;
        @include media-breakpoint-down(md) {
          min-width: 50%;
          width: 50%;
        }
      }
      span {
        color: $primary;
        font-style: italic;
        position: relative;
        em {
          color: $secondary;
        }
        &::before {
          content: "« ";
        }
        &::after {
          content: " »";
        }
      }
    }
    ul {
      padding-left: 1rem;
      border-left: 2px solid $primary;
      list-style-type: none;
      .date {
        color: $primary;
      }
    }
  }
}
.h--partnership {
  position: relative;
  min-height: 40vh;
  background-color: $primary;
  @extend .flex-middle;
  .c--content {
    width: 90%;
    max-width: 570px;
    text-align: center;
    h2 {
      @extend .h1;
      color: white;
      text-transform: uppercase;
      margin-bottom: 1rem;
      font-weight: 600;

      @extend .titled-font;
    }
    h5 {
      @extend .h6;
      color: white;
      margin-bottom: 2rem;
    }
    a {
      @extend .btn;
      background-color: white;
      color: #222;
    }
  }
}
.give--p {
  padding: 5vh 10px;
  .g--wrapper {
    @extend .container;
    .title {
      @extend .titled-font;
      text-align: center;
      color: $primary;
      font-weight: bold;
      margin-bottom: 2rem;
    }
    .c--wrapper {
      @extend .flex-middle;
    }
    .card {
      max-width: 560px;
    }
  }
}
.h--branches {
  padding: 10vh 10px;
  .h--wrapper {
    @extend .container;
    .title {
      text-align: center;
      margin-bottom: 5vh;
      font-weight: 700;
      color: $primary;

      @extend .titled-font;
    }
    ul {
      @extend .list-unstyled;
      text-align: center;
      li {
        display: inline-block;
        max-width: 300px;
        .item-displayer {
          background-color: #fff;
          border: 1px solid #ececec;
          padding: 10px;
          margin-bottom: 15px;
          margin-right: 15px;
          h4 {
            font-size: 1rem;
            font-weight: bold;
            color: #555;
          }
          .subtitle {
            @extend .text-muted;
          }
        }
      }
    }
  }
}

.b--page {
  padding: 5vh 10px;
  @extend .flex-middle;
  .pb--wrapper {
    max-width: 860px;
    .m-title {
      text-align: center;
      margin-bottom: 5vh;
      @extend .titled-font;
    }
    .section {
      margin-bottom: 5vh;
      h1 {
        @extend .h3;
        color: $primary;
        @extend .titled-font;
        font-weight: bold;
      }
      p {
        color: #555;
        font-size: 1rem;
      }
    }
  }
}
.give--waiting {
  min-height: 70vh;
  @extend .flex-middle;
  justify-content: center;
  align-items: center;
  text-align: center;
  h1 {
    @extend .h2;
    @extend .titled-font;
    color: $primary;
    text-align: center;
  }
}
.bg-whitesmoke {
  background-color: whitesmoke;
}

.program-page {
  min-height: 60vh;
  padding: 5vh 10px;

  .p--wrapper {
    @extend .container;
    .title {
      @extend .h2;
      @extend .titled-font;
      color: $primary;
      margin-bottom: 3rem;
    }
    .program-displayer {
      margin-bottom: 1rem;
      img {
        width: 100%;
        margin-bottom: 1rem;
      }
      .p--title {
        @extend .h5;
        color: #222;
        @extend .titled-font;
        margin-bottom: 0.7rem;
      }
      .p--subtitle {
        @extend .h6;
        @extend .text-muted;
        text-transform: lowercase;
      }
      a {
        display: inline-block;
        @extend .small;
        text-decoration: none;
        background-color: $primary;
        color: white;
        border-radius: 25px;
        padding: 5px 20px;
        font-weight: bold;
      }
    }
  }
}

.gallery-p {
  min-height: 60vh;
  padding: 5vh 10px;
  .wrapper {
    @extend .container;
    .title {
      @extend .h2;
      @extend .titled-font;
      color: $primary;
      margin-bottom: 3rem;
      text-align: center;
      text-transform: uppercase;
    }
    .album-selector {
      @extend .flex-row;
      flex-wrap: nowrap;
      overflow: scroll;
      margin-bottom: 1rem;
      .al--item {
        margin-right: 1rem;
        @extend .btn;
        @extend .btn-outline-primary;
        white-space: nowrap;
        &:hover {
          color: white;
        }
      }
    }
  }
}
.newsletter-c {
  padding: 5vh 15px;
  @extend .flex-middle;
  .nc--wrapper {
    width: 100%;
    max-width: 760px;
    .nc--title {
      text-align: center;
      @extend .h3;
      margin-bottom: 1rem;
    }
    .nc--subtitle {
      text-align: center;
      @extend .h5;
      @extend .text-muted;
      margin-bottom: 1rem;
    }
    form {
      input {
        outline: none;
        &:active,
        &:focus {
          outline: none !important;
          box-shadow: none !important;
        }
      }
      .btn {
        border-radius: 0 !important;
        color: white;
        margin-left: 0.5rem;
      }
    }
  }
}
