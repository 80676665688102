@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville:wght@400;700&display=swap");
@import "variables";
@import "~bootstrap/scss/bootstrap";

//HTML
html,
body {
  scroll-behavior: smooth;
  overflow: auto;
  padding: 0;
  margin: 0;
  width: 100%;
  font-weight: 400;
  color: #222;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
}

.titled-font {
  font-family: "Libre Baskerville", serif;
}

body {
  position: relative;
  width: 100%;
  padding: 0;
  margin: 0;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  background: #fff;
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.flex-rsb {
  @extend .flex-row;
  justify-content: space-between;
}
.flex-middle {
  @extend .flex-row;
  justify-content: center;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="date"],
input[type="search"],
textarea,
select {
  outline: none;
  background-color: $white;
  border: 1px solid #ccc;
  height: 40px;
  border-radius: 0 !important;
  color: #333;
  &:active,
  &:focus {
    outline: none !important;
    box-shadow: none !important;
    background-color: white;
  }
}
textarea {
  min-height: 120px !important;
}
.form-group {
  @extend .mb-3;
}
.input-group .form-group {
  margin-bottom: unset !important;
  input[type="search"] {
    border-top-right-radius: 0;
    border-end-end-radius: 0;
  }
}

.btn {
  padding: 8px 50px;
  border-radius: 100px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: 1px;
  &.btn-secondary {
    color: white;
  }
}

.mobile--only {
  @include media-breakpoint-down(md) {
    display: block;
  }
  @include media-breakpoint-up(md) {
    display: none;
  }
}
.desktop--only {
  @include media-breakpoint-down(md) {
    display: none;
  }
  @include media-breakpoint-up(md) {
    display: block;
  }
}
